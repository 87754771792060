import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useDispatch } from "react-redux";
import { updateHeaderStyle } from "../store/actions/base";
import "bootstrap/dist/css/bootstrap.min.css";
import { renderBlock } from "../helpers/renderBlock";
import { Layout } from "../components/Layout";

export default ({ data }) => {
  const components = data.wordpressPage.acf.components_page;

  let isLightHeader = false;
  components &&
    components.forEach(element => {
      if (
        [
          "WordPressAcf_main_page_heading_block",
          "WordPressAcf_inner_page_heading_block",
          "WordPressAcf_media_promo_slider_block",
        ].includes(element.__typename)
      ) {
        isLightHeader = true;
      }
    });

  const pageId = data.wordpressPage.wordpress_id;

  const dispatch = useDispatch();

  useEffect(() => {
    updateHeaderStyle(dispatch, isLightHeader);
  }, []);

  return (
    <Layout data={data.wordpressPage}>
      {components &&
        components.map((item, i) => {
          const beginIndex = item.id ? item.id.indexOf("Acf_") : -1;
          const className =
            beginIndex === -1
              ? "block_" + i
              : item.id.slice(beginIndex + 4) + "_" + i;
          return (
            <div key={i} className={className}>
              {renderBlock(item, pageId)}
            </div>
          );
        })}
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      wordpress_id
      type
      title
      yoast {
        title
        metadesc
      }
      polylang_current_lang
      polylang_translations {
        polylang_current_lang
        id
        path
        slug
        status
      }
      acf {
        components_page {
          ... on WordPressAcf_main_page_heading_block {
            __typename
            ...MainPageHeadingBlock
          }
          ... on WordPressAcf_inner_page_heading_block {
            __typename
            ...InnerPageHeadingBlock
          }
          ... on WordPressAcf_about_block {
            __typename
            ...AboutBlock
          }
          ... on WordPressAcf_our_awards {
            __typename
            ...OurAwardsBlock
          }
          ... on WordPressAcf_history_company_external {
            __typename
            ...HistoryCompanyExternal
          }
          ... on WordPressAcf_advantages_block {
            __typename
            ...AdvantagesBlock
          }
          ... on WordPressAcf_advantages_simple_block {
            __typename
            ...AdvantagesSimpleBlock
          }
          ... on WordPressAcf_projects_block {
            __typename
            ...ProjectsBlock
          }
          ... on WordPressAcf_finance_block {
            __typename
            ...ProjectFinanceBlock
          }
          ... on WordPressAcf_products_block {
            __typename
            ...ProductsBlock
          }
          ... on WordPressAcf_products_extended_block {
            __typename
            ...ProductsExtendedBlock
          }
          ... on WordPressAcf_info_block {
            __typename
            ...InfoBlock
          }
          ... on WordPressAcf_news_block {
            __typename
            ...NewsBlock
          }
          ... on WordPressAcf_crumbs_with_title_block {
            __typename
            ...CrumbsWithTitleBlock
          }
          ... on WordPressAcf_footer_links_block {
            __typename
            ...FooterLinksBlock
          }
          ... on WordPressAcf_project_technology_block {
            __typename
            ...ProjectTechnologyBlock
          }
          ... on WordPressAcf_BondsCirculationBlock {
            __typename
            ...BondsCirculationBlock
          }
          ... on WordPressAcf_how_to_buy_bonds_block {
            __typename
            ...HowBuyBondsBlock
          }
          ... on WordPressAcf_bounds_calculator_block {
            __typename
            ...BoundsCalculatorBlock
          }
          #... on WordPressAcf_faq_block {
          #  __typename
          #  ...FaqBlock
          #}
          #... on WordPressAcf_loans_calculator_block {
          #  __typename
          #  ...LoansCalculatorBlock
          #}
          #... on WordPressAcf_career_video_block {
          #  __typename
          #  ...CareerVideoBlock
          #}
          ... on WordPressAcf_files_block {
            __typename
            ...FilesBlock
          }
          ... on WordPressAcf_media_promo_slider_block {
            __typename
            ...MediaPromoSliderBlock
          }
          ... on WordPressAcf_media_files_list_block {
            __typename
            ...MediaFilesListBlock
          }
          ... on WordPressAcf_contacts_cities_list_block {
            __typename
            ...ContactsCitiesListBlock
          }
          ... on WordPressAcf_contacts_callback_form_block {
            __typename
            ...ContactsCallbackFormBlock
          }
          ... on WordPressAcf_technology_list_block {
            __typename
            ...TechnologyListBlock
          }
          # ... on WordPressAcf_dev_team_block {
          #   __typename
          #   ...DevTeamBlock
          # }
          ... on WordPressAcf_read_also_news_block {
            __typename
            ...ReadAlsoNewsBlock
          }
          ... on WordPressAcf_progress_advantages_block {
            __typename
            ...ProgressAdvantagesBlock
          }
          ... on WordPressAcf_progress_activity_block {
            __typename
            ...ProgressActivityBlock
          }
          ... on WordPressAcf_technology_develop_block {
            __typename
            ...TechnologyDevelopBlock
          }
          ... on WordPressAcf_technology_our_teams_block {
            __typename
            ...TechnologyOurTeamsBlock
          }
          ... on WordPressAcf_graph_block {
            __typename
            ...GraphBlock
          }
          #... on WordPressAcf_helpful_information_block {
          #  __typename
          #  ...HelpfulInformationBlock
          #}
          #  ... on WordPressAcf_open_vacancies_block {
          # __typename
          #  ...OpenVacanciesBlock
          #}
          #... on WordPressAcf_project_team_block {
          #  __typename
          #  ...ProjectTeamBlock
          #}
        }
      }
    }
  }
`;
